@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
@import url(https://fonts.googleapis.com/css?family=Ubuntu);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-wrapper {
  background-color: #000000;
  margin: 0;
  height: 100vh;
}

p {
  display: block;
  width: 0px;
  margin: 0;
  font-size: 1.2rem;
  color: #20c20e;
  overflow: visible;
  font-family: monospace;
  white-space: nowrap;
  -webkit-animation-name: writeText;
          animation-name: writeText;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

h1 {
  text-align: center;
  opacity: 0;
  color: white;
  font-family: sans-serif;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 12000ms;
          animation-delay: 12000ms;
}

.delay1 {
  -webkit-animation-delay: 3000ms;
          animation-delay: 3000ms;
}
.delay2 {
  -webkit-animation-delay: 4000ms;
          animation-delay: 4000ms;
}
.delay3 {
  -webkit-animation-delay: 5000ms;
          animation-delay: 5000ms;
}
.delay4 {
  -webkit-animation-delay: 6000ms;
          animation-delay: 6000ms;
}
.delay5 {
  -webkit-animation-delay: 7000ms;
          animation-delay: 7000ms;
}
.delay6 {
  -webkit-animation-delay: 8000ms;
          animation-delay: 8000ms;
}
.delay7 {
  -webkit-animation-delay: 9000ms;
          animation-delay: 9000ms;
}
.delay8 {
  -webkit-animation-delay: 10000ms;
          animation-delay: 10000ms;
}

/* Standard syntax */
@-webkit-keyframes writeText {
  from {
    width: 0;
  }
  to {
    width: 200px;
  }
}
@keyframes writeText {
  from {
    width: 0;
  }
  to {
    width: 200px;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#loader {
  width: 100%;
  height: 2px;
  background-color: black;
}

#loaded {
  height: 2px;
  width: 0px;
  background-color: #20c20e;
  -webkit-animation-name: load;
          animation-name: load;
  -webkit-animation-duration: 3000ms;
          animation-duration: 3000ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes load {
  0% {
    width: 0;
  }
  50% {
    width: 70%;
  }
  70% {
    width: 100%;
  }
  80% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes load {
  0% {
    width: 0;
  }
  50% {
    width: 70%;
  }
  70% {
    width: 100%;
  }
  80% {
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

body {
  background: white;
}
.main {
  padding-top: 60px;
  padding-bottom: 2em;
  min-height: calc(100vh - 75px);
}
.wrapper {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.linux {
  background: linear-gradient(45deg, #57003f 0%, #f57453 100%);
  font-family: "Ubuntu";
}

.macos {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.infoCardContainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.resume {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.resume > a {
	text-decoration: none;
	color: green;
	font-weight: bold;
}

.sideCard {
  margin: 4em 0;
}

.center {
  text-align: center;
}

.text-danger {
  color: red;
}
.text-muted {
  color: gray;
}
.text-white {
  color: white;
}

.card-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 1em;
}

.card-header {
  text-align: center;
}

.card-description {
  font-size: 16px;
}

.img-fluid {
  width: 100%;
}

.screen-header {
  padding-top: 2em;
}

.spaced {
  margin: 2em 0;
}

@-webkit-keyframes fadeColorBlue {
  from {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}

@keyframes fadeColorBlue {
  from {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}
@-webkit-keyframes fadeColorGreen {
  from {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}
@keyframes fadeColorGreen {
  from {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}

.notFoundImageContainer{
    max-width: 250px;
    margin: 5em auto;
}
.terminal-base > * > *{
	overflow-x: visible !important;
}


