body {
  background: white;
}
.main {
  padding-top: 60px;
  padding-bottom: 2em;
  min-height: calc(100vh - 75px);
}
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linux {
  background: linear-gradient(45deg, #57003f 0%, #f57453 100%);
  font-family: "Ubuntu";
}

.macos {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.infoCardContainer {
  height: fit-content;
}

.resume {
	display: flex;
	flex-direction: column;
}

.resume > a {
	text-decoration: none;
	color: green;
	font-weight: bold;
}

.sideCard {
  margin: 4em 0;
}

.center {
  text-align: center;
}

.text-danger {
  color: red;
}
.text-muted {
  color: gray;
}
.text-white {
  color: white;
}

.card-actions {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.card-header {
  text-align: center;
}

.card-description {
  font-size: 16px;
}

.img-fluid {
  width: 100%;
}

.screen-header {
  padding-top: 2em;
}

.spaced {
  margin: 2em 0;
}

@keyframes fadeColorBlue {
  from {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}
@keyframes fadeColorGreen {
  from {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
}
